<script setup>
import { computed } from 'vue';
import { CdrText } from '@rei/cedar';
import CurrentTime from './CurrentTime.vue';
import useLocationStore from '../../stores/calendar/location';
import useReservationsStore from '../../stores/calendar/reservations';

const reservations = useReservationsStore();
const location = useLocationStore();

const style = computed(() => ({
  '--num-of-blocks': location.interval / reservations.interval,
}));
</script>

<template>
  <div
    class="reservation-timeline"
    :style="style"
  >
    <div class="reservation-timeline__blocker" />
    <CurrentTime :offset="reservations.offset" />
    <div class="reservation-timeline__inner">
      <CdrText
        v-for="(time, index) in location.times"
        :key="index"
        class="reservation-timeline__time"
      >
        {{ time }}
      </CdrText>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.reservation-timeline {
  display: flex;
  flex-direction: row;
  position: relative;
  width: fit-content;
  height: calc($cdr-space-four-x + $cdr-space-one-x);
  background-color: #fff;

  &__blocker {
    position: sticky;
    top: 0;
    left: 0;
    background: $cdr-color-background-secondary;
    border-top: 1px solid $cdr-color-border-secondary;
    width: 168px;
    z-index: 11;
    margin-top: $cdr-space-two-x;
    height: $cdr-space-three-x;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    padding: $cdr-space-three-quarter-x 0;
    background: $cdr-color-background-secondary;
    border-top: 1px solid $cdr-color-border-secondary;
    margin-top: $cdr-space-two-x;
    height: $cdr-space-three-x;
  }

  &__time {
    @include cdr-text-utility-sans-200;
    color: #000;
    min-width: calc(var(--num-of-blocks) * var(--cell-size));
    text-align: center;
  }
}
</style>
