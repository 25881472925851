/**
 * Logs a message at a certain level using JS console
 * @param {String} message the message to log
 * @param {String} level the level for the log: debug, info, warn, error, or log (default)
 * @returns the full log string
 */
const log = (message, level = 'log') => {
  const lvl = level.toLowerCase();
  const msg = `[Rentals ${lvl}] ${message}`;
  // eslint-disable-next-line no-console
  console[lvl](msg);
  return msg;
};

export default log;
