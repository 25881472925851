<script setup>
import useAppStore from '../../stores/global/app';
import pageNames from '../../utils/constants';
import EntryComponent from '../../components/global/EntryComponent.vue';

const app = useAppStore();

app.pageName = pageNames.NOT_FOUND;
</script>

<template>
  <EntryComponent
    title="Uh-oh, page not found"
    body="Let’s go to the Rentals Hourly Calendar."
  />
</template>
