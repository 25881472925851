/**
 * Caclulates how many pixels to offset based on the params
 * @param {Number} blockSize how many blocks is each time interval
 * @param {Number} interval the length of the interval in minutes
 * @param {Number} locationOpen the store open time in hours
 * @param {Number} currentHours the current time's hours
 * @param {Number} currentMinutes the current time's minutes
 * @returns how many pixels to offset
 */
const calculateLeftOffset = (blockSize, interval, locationOpen, currentHours, currentMinutes) => {
  const pixelRatio = blockSize / interval;
  const openTimeOffset = 60 * locationOpen;
  const totalMinutesPastZero = currentHours * 60 + currentMinutes;
  return pixelRatio * (totalMinutesPastZero - openTimeOffset);
};

export default calculateLeftOffset;
