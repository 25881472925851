import { useIntervalFn } from '@vueuse/core';
import useCalendarStore from '../stores/calendar';

/**
 * Creates an interval which updates the current time every minute.
 * @returns isActive, pause, and resume for the interval
 */
const useCalendarTimers = () => {
  const calendar = useCalendarStore();
  // Create an inital time to offset when the page is loaded
  const initialIntervalTime = (60 - calendar.currentTime.getSeconds()) * 1000;
  const finalInterval = useIntervalFn(calendar.updateTime, 60000, { immediate: false });
  const initialInterval = useIntervalFn(
    () => {
      calendar.updateTime();
      finalInterval.resume();
      if (initialInterval.isActive) {
        initialInterval.pause();
      }
    },
    initialIntervalTime,
    {
      immediate: false,
    },
  );

  return {
    isActive: initialInterval.isActive,
    pause: initialInterval.pause,
    resume: initialInterval.resume,
  };
};

export default useCalendarTimers;
