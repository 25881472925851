import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { convertTimeStringToNumberOfHours, createTimeList } from '../../utils/dates';
import getLocationHoursByStoreNumber from '../../api/calendar/location';

const useLocationStore = defineStore('location', () => {
  /* State */
  const storeNumber = ref(-1);
  const companyId = ref(-1);
  const name = ref('');
  const open = ref(0);
  const close = ref(0);
  const interval = ref(30);

  /* Getters */
  const times = computed(() => createTimeList(open.value, close.value, interval.value));

  /* Actions */

  /**
   * Gets the location by store number and sets the values in the store
   * @param {Object} opts options for the HTTP request
   */
  const getLocationByStoreNumber = async (date, opts = {}) => {
    const json = await getLocationHoursByStoreNumber(storeNumber.value, date, opts);
    companyId.value = json.companyId;
    name.value = json.storeName;
    if (json.hours && json.hours.openTime && json.hours.closeTime) {
      open.value = convertTimeStringToNumberOfHours(json.hours.openTime) - 1;
      close.value = convertTimeStringToNumberOfHours(json.hours.closeTime) + 1;
    }
  };

  return {
    /* State */
    storeNumber,
    companyId,
    name,
    open,
    close,
    interval,
    /* Getters */
    times,
    /* Actions */
    getLocationByStoreNumber,
  };
});

export default useLocationStore;
