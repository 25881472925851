import { getCurrentInstance, toRefs } from 'vue';

/**
 * Access the MSAL instance
 * @returns the msal instance, accounts, and whether an msal request is in progress
 */
const useMsal = () => {
  const internalInstance = getCurrentInstance();

  if (!internalInstance) {
    throw Error('useMsal() cannot be called outside the setup() function of a component');
  }

  const { instance, accounts, inProgress } = toRefs(
    internalInstance.appContext.config.globalProperties.$msal,
  );

  // eslint-disable-next-line vue/no-ref-as-operand
  if (!instance || !accounts || !inProgress) {
    throw Error('Please install the msalPlugin');
  }

  return {
    instance: instance.value,
    accounts,
    inProgress,
  };
};

export default useMsal;
