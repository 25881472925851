<script setup>
import { computed } from 'vue';
import useCalendarStore from '../../stores/calendar';

const props = defineProps({
  offset: { type: Number, default: 0 },
  type: { type: String, default: 'reservations' },
});

const calendar = useCalendarStore();

const style = computed(() => ({
  '--offset': `${props.offset + calendar.getLeftOffset(props.type)}px`,
}));
</script>

<template>
  <div
    class="current-time-line"
    :style="style"
  />
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.current-time-line {
  position: absolute;
  top: 0;
  left: calc(var(--offset) - 3px);
  z-index: 3;
  background-color: #47bcc1;
  width: 3px;
  height: var(--cell-size);
}
</style>
