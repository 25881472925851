import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: '32f8c25f-2c0a-4c8b-9bba-fdb0ed5893a3',
    authority: 'https://login.microsoftonline.com/b4c59c9e-a967-4bea-921c-594b42207f17',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Request access for this app's API resource
export const loginRequest = {
  scopes: ['api://32f8c25f-2c0a-4c8b-9bba-fdb0ed5893a3/access_as_user'],
};
