<script setup>
import { computed } from 'vue';
import { CdrText, CdrSelect } from '@rei/cedar';
import format from 'date-fns/format';
import useCalendarStore from '../../stores/calendar';
import { createDateList, convertStringToDate } from '../../utils/dates';

const calendar = useCalendarStore();

const day = computed(() => {
  return format(convertStringToDate(calendar.date), 'eeee — MMMM dd, yyyy');
});

const dates = createDateList(new Date());
</script>

<template>
  <div
    class="calendar-date-select"
    data-ui="calendar-date-select"
  >
    <CdrText
      class="calendar-date-select__day"
      tag="p"
      data-ui="calendar-date-select-day"
    >
      {{ day }}
    </CdrText>
    <CdrSelect
      v-model="calendar.date"
      class="calendar-date-select__dates"
      label="Select date"
      :options="dates"
      hide-label
      data-ui="calendar-date-select-dates"
      @change="($event) => calendar.updateDate($event.target.value)"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.calendar-date-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $cdr-space-one-x;

  &__day {
    @include cdr-text-body-strong-300;
  }

  @include cdr-lg-mq-up {
    gap: $cdr-space-two-x;

    &__day {
      @include cdr-text-body-strong-500;
    }
  }
}
</style>

<style lang="scss">
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.calendar-date-select {
  &__dates {
    max-width: 115px;
  }

  @include cdr-md-mq-up {
    &__dates {
      max-width: initial;
    }
  }
}
</style>
