import { defineStore } from 'pinia';
import { ref } from 'vue';

const useErrorStore = defineStore('error', () => {
  /* State */
  const internal = ref(false);
  const external = ref(false);
  const title = ref('');
  const body = ref('');

  return {
    /* State */
    internal,
    external,
    title,
    body,
  };
});

export default useErrorStore;
