import HomePage from '../pages/home/HomePage.vue';
import CalendarPage from '../pages/calendar/CalendarPage.vue';
import NotFoundPage from '../pages/not-found/NotFoundPage.vue';
import FailedPage from '../pages/failed/FailedPage.vue';

const routes = [
  {
    path: '/',
    name: 'index',
    component: HomePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/calendar/:storeNumber',
    name: 'calendar',
    component: CalendarPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/failed',
    name: 'failed',
    component: FailedPage,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundPage,
  },
];

export default routes;
