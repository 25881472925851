<script setup>
import useCalendarStore from '../../stores/calendar';
import CurrentTimeLine from './CurrentTimeLine.vue';
import ReservationComponent from './ReservationComponent.vue';
import TimeComponent from './TimeComponent.vue';

const props = defineProps({
  reservation: { type: Object, required: true },
});

const calendar = useCalendarStore();

const getFerryTicketCount = (reservation) => {
  return (
    reservation.ferryTickets &&
    reservation.ferryTickets.reduce((prev, curr) => prev + curr.quantity, 0)
  );
};
</script>

<template>
  <div class="reservation-row">
    <CurrentTimeLine />
    <TimeComponent
      v-for="timeIdx in calendar.location.times.length - 1"
      :key="timeIdx"
      :interval-ratio="calendar.reservationsIntervalRatio"
    />
    <ReservationComponent
      :start-time="props.reservation.startTime"
      :start-date="props.reservation.startDate"
      :end-time="props.reservation.dueTime"
      :end-date="props.reservation.dueDate"
      :status="props.reservation.status"
      :quantity="props.reservation.quantity"
      :link="props.reservation.reservationLink"
      :items="props.reservation.items"
      :ferry-ticket-count="getFerryTicketCount(props.reservation)"
    />
    <div class="reservation-row__placeholder" />
  </div>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.reservation-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $cdr-color-border-primary;
  position: relative;

  &__placeholder {
    width: var(--cell-size);
    height: var(--cell-size);
  }
}
</style>
