<script setup>
import ReservationTime from './ReservationTime.vue';
import ReservationInfo from './ReservationInfo.vue';
import useReservationsStore from '../../stores/calendar/reservations';

const reservations = useReservationsStore();
</script>

<template>
  <div class="reservation-grid">
    <div class="reservation-grid__info">
      <ReservationInfo
        v-for="(reservation, idx) in reservations.items"
        :key="idx"
        :first-name="reservation.firstName"
        :last-name="reservation.lastName"
        :status="reservation.status"
      />
    </div>
    <div class="reservation-grid__times">
      <ReservationTime
        v-for="(reservation, idx) in reservations.items"
        :key="idx"
        :reservation="reservation"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.reservation-grid {
  display: flex;
  flex-direction: row;

  &__times {
    display: flex;
    flex-direction: column;
  }

  &__info {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: #fff;
  }
}
</style>
