<script setup>
import { onMounted, onUnmounted } from 'vue';
import { useIntervalFn } from '@vueuse/core';
import { useRouteParams } from '@vueuse/router';
import {
  CdrContainer,
  CdrText,
  CdrTabs,
  CdrTabPanel,
  CdrSkeleton,
  CdrSkeletonBone,
} from '@rei/cedar';
import useCalendarStore from '../../stores/calendar';
import AlertComponent from '../../components/global/AlertComponent.vue';
import EntryComponent from '../../components/global/EntryComponent.vue';
import ErrorComponent from '../../components/global/ErrorComponent.vue';
import CustomerReservations from '../../components/calendar/CustomerReservations.vue';
import TrafficSchedule from '../../components/calendar/TrafficSchedule.vue';
import pageNames from '../../utils/constants';
import useCalendarTimers from '../../composables/useCalendarTimers';

const storeNumber = useRouteParams('storeNumber');

// Create and share store globally
const calendar = useCalendarStore();
calendar.app.pageName = pageNames.CALENDAR;
calendar.location.storeNumber = storeNumber.value;
const {
  resume: resumeCalendarTimers,
  isActive: isActiveCalendarTimers,
  pause: pauseCalendarTimers,
} = useCalendarTimers();
const {
  resume: resumeRefresh,
  isActive: isActiveRefresh,
  pause: pauseRefresh,
} = useIntervalFn(
  async () => {
    await calendar.refresh();
  },
  60000 * 5,
  { immediate: false },
);
calendar.app.loading = true;

onMounted(async () => {
  if (calendar.app.msalEnabled) {
    await calendar.app.fetchToken();
  }

  await calendar.init();
  resumeCalendarTimers();
  resumeRefresh();
});

onUnmounted(() => {
  if (isActiveCalendarTimers) {
    pauseCalendarTimers();
  }
  if (isActiveRefresh) {
    pauseRefresh();
  }
});

const tabChange = (state, tabId) => {
  if (state) {
    switch (tabId) {
      case 'traffic-schedule-panel':
        calendar.activeTab = 'traffic';
        break;
      default:
        calendar.activeTab = 'reservations';
    }
  }
};
</script>

<template>
  <EntryComponent
    v-if="calendar.error.external"
    title="Oops!"
    body="There’s a problem with network authentication."
  />
  <CdrContainer
    v-else
    class="calendar-page"
    data-ui="calendar-page"
  >
    <div class="calendar-page__sub-header">
      <CdrText
        class="calendar-page__sub-title"
        tag="h2"
        data-ui="calendar-page-sub-title"
      >
        Hourly Dashboard
      </CdrText>
      <AlertComponent v-if="!calendar.app.loading" />
    </div>
    <CdrSkeleton
      v-if="calendar.app.loading"
      class="calendar-page__skeleton"
    >
      <CdrSkeletonBone type="rectangle" />
    </CdrSkeleton>
    <ErrorComponent v-else-if="!calendar.app.loading && calendar.error.internal" />
    <template v-else>
      <CdrTabs
        class="calendar-page__tabs"
        height="auto"
      >
        <CdrTabPanel
          id="customer-reservations-tab-panel"
          name="Customer Reservations"
          aria-labelledby="customer-reservations-tab"
          @tabChange="tabChange"
        >
          <CustomerReservations />
        </CdrTabPanel>
        <CdrTabPanel
          id="traffic-schedule-tab-panel"
          name="Traffic Schedule"
          aria-labelledby="traffic-schedule-tab"
          @tabChange="tabChange"
        >
          <TrafficSchedule />
        </CdrTabPanel>
      </CdrTabs>
      <CdrText class="calendar-page__refresh">
        This page automatically refreshes every 5 minutes, but feel free to hit the Refresh button
        on your browser anytime.
      </CdrText>
    </template>
  </CdrContainer>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.calendar-page {
  margin-bottom: $cdr-space-one-and-a-half-x;

  &__sub-header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: $cdr-space-one-x;
  }

  &__sub-title {
    @include cdr-text-heading-serif-600;
    color: $cdr-color-text-emphasis;
  }

  &__skeleton {
    margin-top: $cdr-space-three-x;
    height: 80vh;
  }

  &__tabs {
    overflow-x: unset;
  }

  &__refresh {
    @include cdr-text-utility-sans-200;
    color: $cdr-color-text-secondary;
    max-width: 500px;
  }
}
</style>
