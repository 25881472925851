<script setup>
import { ref, watch, onMounted, nextTick } from 'vue';
import useCalendarStore from '../../stores/calendar';

const calendar = useCalendarStore();

const one = ref(null);
const two = ref(null);

const scroll = (scrollAmount) => {
  one.value.scrollLeft = scrollAmount;
  two.value.scrollLeft = scrollAmount;
};

const handleScroll = (e) => {
  scroll(e.target.scrollLeft);
};

// Scroll based on scrollPos
onMounted(async () => {
  await nextTick();
  if (!calendar.loading) {
    scroll(calendar.scrollPos);
  }
});

watch(
  () => [calendar.scrollPos],
  async () => {
    await nextTick();
    if (!calendar.loading) {
      scroll(calendar.scrollPos);
    }
  },
);
</script>

<template>
  <section class="multi-scroll">
    <div
      ref="one"
      class="multi-scroll__one--outer"
      data-ui="multi-scroll-one"
      @scroll="handleScroll"
    >
      <div class="multi-scroll__one--inner">
        <slot name="one" />
      </div>
    </div>
    <div
      ref="two"
      class="multi-scroll__two--outer"
      data-ui="multi-scroll-two"
      @scroll="handleScroll"
    >
      <div class="multi-scroll__two--inner">
        <slot name="two" />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.multi-scroll {
  &__one--outer,
  &__two--outer {
    overflow-x: auto;
  }

  &__one--outer {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 12;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__one--inner {
    display: flex;
    flex-direction: row;
    position: relative;
    width: fit-content;
  }

  &__two--inner {
    display: flex;
    flex-direction: row;
    width: fit-content;
  }
}
</style>
