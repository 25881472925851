<script setup>
import { RouterView } from 'vue-router';
import BackToTopBtn from '@rei/back-to-top-btn';
import HeaderComponent from '../components/global/HeaderComponent.vue';
import useAppStore from '../stores/global/app';

const props = defineProps({
  msalEnabled: { type: Boolean, required: true },
  porUrl: { type: String, required: true },
});

const app = useAppStore();
app.init(props);
</script>

<template>
  <div
    class="app"
    data-ui="app"
  >
    <HeaderComponent />
    <section class="app__container">
      <RouterView />
      <BackToTopBtn
        :min-height-multiplier="2"
        :scroll-multiplier="0.5"
      />
    </section>
  </div>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.app {
  &__container {
    position: relative;
  }
}
</style>

<style lang="scss">
:root {
  --cell-size: 96px;
}
</style>
