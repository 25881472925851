<script setup>
import { computed } from 'vue';
import ReservationStatus from './ReservationStatus.vue';

const props = defineProps({
  firstName: { type: String, required: true },
  lastName: { type: String, required: true },
  status: { type: String, required: true },
});

const name = computed(() => {
  const firstNameLetter = props.firstName[0]?.toUpperCase();
  return `${firstNameLetter ? `${firstNameLetter}. ` : ''} ${props.lastName}`;
});
</script>

<template>
  <div class="reservation-info">
    <div class="reservation-info__customer-name">{{ name }}</div>
    <div class="reservation-info__status">
      <ReservationStatus :status="props.status" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.reservation-info {
  display: flex;
  flex-direction: row;
  border: 1px solid $cdr-color-border-primary;
  border-top: none;
  width: fit-content;

  &__customer-name,
  &__status {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__customer-name {
    @include cdr-text-utility-sans-400;
    min-width: calc(3.5 * $cdr-space-three-x);
    color: $cdr-color-text-emphasis;
    padding: 10px $cdr-space-three-quarter-x;
    height: var(--cell-size);
  }

  &__status {
    border-left: 1px solid $cdr-color-border-primary;
    width: calc(2 * $cdr-space-three-x);
    padding: $cdr-space-half-x $cdr-space-quarter-x;
    height: var(--cell-size);
  }
}
</style>
