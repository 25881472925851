<script setup>
import { computed } from 'vue';
import { CdrInput, CdrButton, CdrText, IconSearch } from '@rei/cedar';
import useReservationsStore from '../../stores/calendar/reservations';

const reservations = useReservationsStore();

const formattedTotalDollarsOfReservations = computed(() =>
  reservations.totalDollarsOfReservations.toLocaleString(),
);
</script>

<template>
  <section class="reservation-header">
    <div class="reservation-header__search">
      <CdrInput
        v-model="reservations.search"
        label="Search by last name"
        placeholder="Search by last name"
        data-ui="reservation-header-search"
        hide-label
      >
        <template #pre-icon>
          <IconSearch inherit-color />
        </template>
      </CdrInput>
      <CdrButton @click="reservations.filterReservations()">Search</CdrButton>
    </div>
    <div class="reservation-header__info">
      <CdrText class="reservation-header__total-reservations">
        <strong
          class="reservation-header__text--emphasis"
          data-ui="reservation-header-num-paid-reservations"
        >
          {{ reservations.numberOfReservations }}
        </strong>
        <span class="reservation-header__text"># paid reservations</span>
      </CdrText>
      <CdrText class="reservation-header__total-paid">
        <span>
          $
          <strong
            class="reservation-header__text--emphasis"
            data-ui="reservation-header-total-paid-reservations"
          >
            {{ formattedTotalDollarsOfReservations }}
          </strong>
        </span>
        <span class="reservation-header__text">total paid reservations</span>
      </CdrText>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.reservation-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: $cdr-space-one-and-a-half-x;
  margin: $cdr-space-one-and-a-half-x 0;
  margin-bottom: $cdr-space-two-x;

  &__search,
  &__info {
    display: flex;
    flex-direction: row;
  }

  &__search {
    gap: $cdr-space-half-x;
  }

  &__info {
    gap: $cdr-space-one-and-a-half-x;
  }

  &__total-reservations,
  &__total-paid {
    display: flex;
    flex-direction: column;
    gap: $cdr-space-eighth-x;
    padding: $cdr-space-three-quarter-x 0;
    align-items: center;
    width: 225px;
    background-color: $cdr-color-background-secondary;
  }

  &__text {
    @include cdr-text-utility-sans-200;

    &--emphasis {
      @include cdr-text-subheading-sans-500;
      font-weight: 600;
    }
  }
}
</style>
