<script setup>
import { computed } from 'vue';

const props = defineProps({
  intervalRatio: { type: Number, required: false, default: 1 },
  text: { type: String, required: false, default: '' },
});

const style = computed(() => ({
  '--interval-ratio': props.intervalRatio,
}));

const intervalClasses = computed(() => ({
  [`time__interval`]: true,
  'time__interval--only-one': props.intervalRatio === 1,
}));
</script>

<template>
  <div
    class="time"
    :style="style"
  >
    <div
      v-for="ir in props.intervalRatio"
      :key="ir"
      :class="intervalClasses"
    >
      {{ text }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.time {
  @include cdr-text-utility-sans-200;
  display: flex;
  flex-direction: row;
  width: calc(var(--interval-ratio) * var(--cell-size));
  height: var(--cell-size);
  border-right: 1px solid $cdr-color-border-primary;

  &__interval {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: var(--cell-size);
    height: var(--cell-size);
    border-left: 1px dashed $cdr-color-border-primary;

    &--only-one {
      border-left: none;
    }

    &:first-child {
      border-left: none;
    }
  }
}
</style>
