<script setup>
import { computed } from 'vue';
import { CdrText, CdrLink, IconCaretLeft, IconCaretRight } from '@rei/cedar';
import { isEqual, startOfDay, differenceInSeconds } from 'date-fns';
import { convertStringToDate, intervalAmountInTimeSlot } from '../../utils/dates';
import useStatusStyles from '../../utils/useStatusStyles';
import useCalendarStore from '../../stores/calendar';

const props = defineProps({
  startTime: { type: Number, required: true },
  startDate: { type: String, required: true },
  endTime: { type: Number, required: true },
  endDate: { type: String, required: true },
  status: { type: String, required: true },
  quantity: { type: Number, required: true },
  link: { type: String, required: true },
  items: { type: Array, required: true },
  ferryTicketCount: { type: Number, default: 0 },
});

const { date, location, reservations } = useCalendarStore();
const startDate = new Date(props.startDate);
const endDate = new Date(props.endDate);
const sodStartDate = startOfDay(startDate);
const sodEndDate = startOfDay(endDate);
const dateObj = startOfDay(convertStringToDate(date));
const startsToday = isEqual(dateObj, sodStartDate);
const endsToday = isEqual(dateObj, sodEndDate);

// Width of time slot
const blocks = computed(() => {
  const startTime = startsToday ? props.startTime : location.open;
  const endTime = endsToday ? props.endTime : location.close;
  const notDueTodayAddon = endsToday ? 0 : 1;

  return intervalAmountInTimeSlot(startTime, endTime, reservations.interval) + notDueTodayAddon;
});
// Time slot padding
const padding = computed(() => {
  const startTime = startsToday ? props.startTime : location.open;
  return intervalAmountInTimeSlot(location.open, startTime, reservations.interval);
});
const style = computed(() => ({
  '--num-of-blocks': blocks.value,
  '--start-padding': padding.value,
  ...useStatusStyles(props.status),
}));

const amountText = computed(() => `${props.quantity} ${props.quantity === 1 ? 'item' : 'items'}`);
const dayDuration = computed(() => {
  return Math.round(Math.floor(differenceInSeconds(endDate, startDate) / 60 / 60 / 24));
});
const hourDuration = computed(() => {
  const secondsDiff = differenceInSeconds(endDate, startDate);
  const dayHours = dayDuration.value > 0 ? dayDuration.value * 24 : 0;
  const hours = secondsDiff / 60 / 60 - dayHours;
  return hours;
});
const durationText = computed(() => {
  const days =
    dayDuration.value > 0 ? `${dayDuration.value} ${dayDuration.value === 1 ? 'day' : 'days'}` : '';
  const hours = `${Number(hourDuration.value.toFixed(2))} ${
    hourDuration.value === 1 ? 'hr' : 'hrs'
  }`;
  return `${days}${days ? ' ' : ''}${hours}`;
});
const typeText = computed(() =>
  props.items.map((item) => `${item.quantity} - ${item.name}`).join(', '),
);
const quickInfo = computed(() => {
  const s = props.ferryTicketCount === 1 ? '' : 's';
  const ferryTicketText =
    props.ferryTicketCount > 0 ? ` / ${props.ferryTicketCount} ticket${s}` : '';
  return `${durationText.value} / ${amountText.value}${ferryTicketText}`;
});
</script>

<template>
  <CdrLink
    class="reservation"
    :href="link"
    :style="style"
    :title="typeText"
    target="_blank"
  >
    <div class="reservation__indicators">
      <div
        v-if="startsToday"
        class="reservation__circle"
      />
      <template v-else>
        <IconCaretLeft class="reservation__caret" />
      </template>
    </div>
    <div class="reservation__content">
      <CdrText class="reservation__header">
        {{ quickInfo }}
      </CdrText>
      <CdrText class="reservation__items">
        {{ typeText }}
      </CdrText>
    </div>
    <div class="reservation__indicators">
      <div
        v-if="endsToday"
        class="reservation__circle"
      />
      <template v-else>
        <IconCaretRight class="reservation__caret" />
      </template>
    </div>
  </CdrLink>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.reservation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: $cdr-space-one-x;
  position: absolute;
  top: 0;
  left: calc(var(--start-padding) * var(--cell-size));
  z-index: 4;
  width: calc(var(--num-of-blocks) * var(--cell-size));
  height: var(--cell-size);
  padding: 0 $cdr-space-three-quarter-x;
  text-decoration: none;

  &__content {
    width: 100%;
  }

  &__header {
    @include cdr-text-utility-sans-strong-400;
    margin-bottom: $cdr-space-quarter-x;
  }

  &__items {
    @include cdr-text-utility-sans-300;
  }

  &__indicators {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__circle {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #bfb33e;
  }

  &__caret {
    fill: $cdr-color-text-inverse;
  }
}
</style>
