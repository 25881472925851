<script setup>
import { CdrContainer, CdrText, CdrSkeleton, CdrSkeletonBone } from '@rei/cedar';
import ReiLogo from '../../icons/ReiLogo.vue';
import useAppStore from '../../stores/global/app';
import useErrorStore from '../../stores/global/error';
import AccountMenu from './AccountMenu.vue';
import CalendarDateSelect from '../calendar/CalendarDateSelect.vue';

const app = useAppStore();
const error = useErrorStore();
</script>

<template>
  <CdrContainer
    class="header"
    tag="header"
    modifier="fluid"
    data-ui="header"
  >
    <div class="header__inner">
      <div class="header__left">
        <ReiLogo class="header__logo" />
        <CdrSkeleton
          v-if="app.loading"
          class="header__skeleton"
        >
          <CdrSkeletonBone type="heading" />
        </CdrSkeleton>
        <CdrText
          v-else
          class="header__title"
          tag="h1"
          data-ui="header-title"
        >
          <template v-if="!error.external && app.isCalendarPage">
            <span class="header__rentals">Rentals:</span>
            {{ app.title }}
          </template>
          <template v-else>Rental Employees</template>
        </CdrText>
      </div>
      <div class="header__right">
        <CalendarDateSelect v-if="!error.external && app.isCalendarPage" />
        <AccountMenu v-if="!app.loading && app.msalEnabled" />
      </div>
    </div>
  </CdrContainer>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.header {
  background-color: #f7f7f7;
  padding: 0;

  &__inner {
    max-width: 1232px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $cdr-space-three-quarter-x $cdr-space-one-x;
  }

  &__left,
  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $cdr-space-one-x;
  }

  &__right {
    gap: $cdr-space-one-and-a-half-x;
  }

  &__logo {
    width: 75px;
    height: 48px;
  }

  &__skeleton {
    width: 200px;
  }

  &__title {
    @include cdr-text-heading-serif-500;
    font-weight: 600;
  }

  &__rentals {
    display: block;
    width: 100%;
  }

  @include cdr-lg-mq-up {
    &__inner {
      padding: $cdr-space-three-quarter-x $cdr-space-two-x;
    }

    &__title {
      @include cdr-text-heading-serif-600;
      font-weight: 600;
    }

    &__rentals {
      display: inline;
    }
  }
}
</style>
