<script setup>
import { ref, computed } from 'vue';
import { CdrText, IconAccountProfile } from '@rei/cedar';
import useMsal from '../../composables/useMsal';

const { instance, accounts } = useMsal();
const opened = ref(false);

const toggleMenu = () => {
  opened.value = !opened.value;
};

const logout = () => {
  opened.value = false;
  instance.logoutRedirect();
};

const isAuthenticated = computed(() => accounts.value.length > 0);
</script>

<template>
  <div
    v-if="isAuthenticated"
    class="account-menu"
  >
    <IconAccountProfile
      class="account-menu__icon"
      tabindex="0"
      @click="toggleMenu"
      @keypress.enter="toggleMenu"
    />
    <nav
      v-if="opened"
      class="account-menu__nav"
    >
      <ul class="account-menu__list">
        <CdrText
          class="account-menu__item"
          tag="li"
          tabindex="0"
          @click="logout"
          @keypress.enter="logout"
        >
          Log out
        </CdrText>
      </ul>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.account-menu {
  position: relative;

  &__icon {
    margin-right: $cdr-space-two-x;

    &:hover {
      cursor: pointer;
    }
  }

  &__nav {
    position: absolute;
    list-style: none;
    margin-top: $cdr-space-one-x;
    padding: $cdr-space-one-and-a-half-x $cdr-space-one-x;
    right: 0;
    width: 188px;
    background-color: #fff;
    border-radius: $cdr-radius-softer;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 10;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    color: $cdr-color-text-link-rest;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
