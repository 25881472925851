import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';
import addMinutes from 'date-fns/addMinutes';
import differenceInMinutes from 'date-fns/differenceInMinutes';

const dates = ['Yesterday', 'Today', 'Tomorrow', 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

/**
 * Creates a list of formatted dates based on the current date
 * @param {Date} date a date
 * @returns a list of formatted dates
 */
export const createDateList = (date) =>
  dates.map((val) => {
    if (typeof val === 'string') {
      return val;
    }
    return format(addDays(date, val), 'MMMM dd, yyyy');
  });

/**
 * Creates a string based on the date, either Yesterday, Today, or Tomorrow
 * @param {Date} value a date
 * @returns a string
 */
export const convertDateToString = (value) => {
  const today = startOfDay(new Date());
  const compare = startOfDay(new Date(value));

  if (isBefore(compare, today)) {
    return 'Yesterday';
  }

  if (isAfter(compare, today)) {
    return 'Tomorrow';
  }

  return 'Today';
};

/**
 * Converts a string to a date object
 * @param {String} value the string to converted
 * @returns a date
 */
export const convertStringToDate = (value) => {
  switch (value) {
    case 'Yesterday':
      return subDays(new Date(), 1);
    case 'Today':
      return new Date();
    case 'Tomorrow':
      return addDays(new Date(), 1);
    default:
      return new Date(value);
  }
};

/**
 * Creates an array of times based on the a start and end time using the specified interval
 * @param {Number} start the hour to start the timeline
 * @param {Number} end the hour to end the timeline
 * @param {Number} interval the minutes between each hour
 * @returns an array of times
 */
export const createTimeList = (start, end, interval) => {
  const ratio = interval / 60;
  const rtn = [];
  let time = addMinutes(startOfDay(new Date()), start * 60);

  for (let i = start; i < end; i += ratio) {
    rtn.push(format(time, 'HH:mm'));
    time = addMinutes(time, interval);
  }

  // To ensure end is always added
  rtn.push(format(addMinutes(startOfDay(new Date()), end * 60), 'HH:mm'));

  return rtn;
};

/**
 * Returns the number of intervals between a start and end time
 * @param {Number} start start time
 * @param {Number} end end time
 * @param {Number} interval minute interval
 * @returns the number of intervals between two times
 */
export const intervalAmountInTimeSlot = (start, end, interval) => {
  if (interval <= 0) {
    return 0;
  }

  const today = startOfDay(new Date());
  const startTime = addMinutes(today, start * 60);
  const endTime = addMinutes(today, end * 60);
  const diff = differenceInMinutes(endTime, startTime);
  return diff / interval;
};

/**
 * Calculates the hour difference between two times
 * @param {Number} start start time
 * @param {Number} end end time
 * @returns the difference between two times
 */
export const hourDifference = (start, end) => {
  const today = startOfDay(new Date());
  const startTime = addMinutes(today, start * 60);
  const endTime = addMinutes(today, end * 60);
  const minuteDiff = differenceInMinutes(endTime, startTime);
  const hourDiff = minuteDiff / 60;

  return hourDiff;
};

/**
 * Calculates the time in hours based on he provided string
 * @param {String} str a time string separated by :
 * @returns the number of hours
 */
export const convertTimeStringToNumberOfHours = (str) =>
  str
    .split(':')
    .map((unit) => Number(unit))
    .reduce((prev, cur) => prev * 60 + cur, 0) /
  60 /
  60;
