import { defineStore } from 'pinia';
import { ref } from 'vue';
import getReservationsByCompanyIdApi from '../../api/calendar/reservations';
import useAlertStore from '../global/alert';

/**
 * Filters the reservation list on the status. Sorts each filtered list by start time and last name in ASC order
 * @param {Array} reservations the array of reservations
 * @param {String} status the status to filter on
 * @returns a filter and sorted list of reservations
 */
export const filterSortReservations = (reservations, status) => {
  return reservations
    .filter((reservation) => reservation.status.toLowerCase() === status.toLowerCase())
    .sort((a, b) => a.startTime - b.startTime)
    .sort((a, b) => a.startTime - b.startTime || a.lastName.localeCompare(b.lastName));
};

const useReservationsStore = defineStore('reservations', () => {
  /* State */
  const search = ref('');
  const interval = ref(15);
  const offset = ref(230.5);
  const numberOfReservations = ref(0);
  const totalDollarsOfReservations = ref(0);
  const originalItems = ref([]);
  const items = ref([]);
  const alert = useAlertStore();

  /* Actions */

  /**
   * Updates the originalItems and items values of the store
   * @param {Array} reservationArr the array of reservations
   */
  const updateReservations = (reservationArr = []) => {
    originalItems.value = [
      ...filterSortReservations(reservationArr, 'late'),
      ...filterSortReservations(reservationArr, 'order'),
      ...filterSortReservations(reservationArr, 'need sig'),
      ...filterSortReservations(reservationArr, 'reserved'),
      ...filterSortReservations(reservationArr, 'received'),
    ];

    items.value = originalItems.value;
  };

  /**
   * Filters the items based on the search value
   */
  const filterReservations = () => {
    items.value = originalItems.value.filter((reservation) =>
      reservation.lastName.toLowerCase().startsWith(search.value.toLowerCase()),
    );
  };

  /**
   * Updates reservation information with data from reservations API call
   * @param {String} companyId the PoR company id
   * @param {String} date the selected date
   * @param {Object} opts fetch options
   */
  const getReservationsByCompanyId = async (companyId, date, opts = {}) => {
    const json = await getReservationsByCompanyIdApi(companyId, date, opts);
    if (json) {
      numberOfReservations.value = json.numberOfReservations;
      totalDollarsOfReservations.value = json.totalDollarsOfReservations;
      updateReservations(json.reservations);
      if (json.overBooked) {
        alert.openOverbookingAlert();
      } else {
        alert.open = false;
        alert.message = '';
      }
    } else {
      alert.open = false;
      alert.message = '';
    }
  };

  return {
    /* State */
    search,
    interval,
    offset,
    numberOfReservations,
    totalDollarsOfReservations,
    items,
    alert,
    /* Actions */
    updateReservations,
    filterReservations,
    getReservationsByCompanyId,
  };
});

export default useReservationsStore;
