/**
 * Determines the background color based on the status
 * Status values:
 *   - late
 *   - need sig
 *   - reserved
 *   - received
 *   - order (default)
 * @param {String} status the store status
 * @returns a style object
 */
const useStatusStyles = (status) => {
  let bgColor;

  switch (status.toLowerCase()) {
    case 'late':
      bgColor = '#c7370f';
      break;
    case 'need sig':
      bgColor = '#47bcc1';
      break;
    case 'reserved':
      bgColor = '#406eb5';
      break;
    case 'received':
      bgColor = '#928b80';
      break;
    default:
      bgColor = '#1f513f';
  }

  return {
    color: '#fff',
    'background-color': bgColor,
  };
};

export default useStatusStyles;
