<script setup>
import { CdrText } from '@rei/cedar';
import CurrentTime from './CurrentTime.vue';
import useLocationStore from '../../stores/calendar/location';
import useTrafficStore from '../../stores/calendar/traffic';

const location = useLocationStore();
const traffic = useTrafficStore();

const timeClass = (idx) => ({
  'traffic-timeline__time--first': idx === 0,
  'traffic-timeline__time--last': idx === location.times.length - 1,
});
</script>

<template>
  <div class="traffic-timeline">
    <CurrentTime
      :offset="traffic.offset"
      type="traffic"
    />
    <div class="traffic-timeline__outer">
      <div class="traffic-timeline__blocker" />
      <div class="traffic-timeline__inner">
        <CdrText
          v-for="(time, idx) in location.times"
          :key="idx"
          class="traffic-timeline__time"
          :class="timeClass(idx)"
        >
          {{ time }}
        </CdrText>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.traffic-timeline {
  display: flex;
  flex-direction: row;
  position: relative;
  width: fit-content;
  border-left: none;
  border-right: none;
  background-color: #fff;
  height: calc($cdr-space-four-x + $cdr-space-two-x);

  &__outer {
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 10;
  }

  &__blocker {
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    border: 1px solid $cdr-color-border-primary;
    width: calc(3 * $cdr-space-three-x);
    height: $cdr-space-four-x;
    z-index: 11;
    margin-top: $cdr-space-two-x;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    margin-top: $cdr-space-two-x;
  }

  &__time {
    @include cdr-text-utility-sans-200;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: var(--cell-size);
    height: $cdr-space-four-x;
    border: 1px solid $cdr-color-border-primary;
    border-left: none;
    padding: $cdr-space-three-quarter-x $cdr-space-quarter-x;
    color: #000;
    background-color: #fff;
  }
}
</style>
