<script setup>
import { CdrText, IconExperiencesRentals } from '@rei/cedar';
import useErrorStore from '../../stores/global/error';

const error = useErrorStore();
</script>

<template>
  <div class="error">
    <IconExperiencesRentals class="error__icon" />
    <CdrText
      class="error__title"
      tag="h3"
    >
      {{ error.title }}
    </CdrText>
    <CdrText
      class="error__body"
      tag="p"
    >
      {{ error.body }}
    </CdrText>
  </div>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;

  &__icon {
    width: calc(2 * $cdr-space-four-x);
    height: calc(2 * $cdr-space-four-x);
    margin-bottom: $cdr-space-one-x;
  }

  &__title {
    @include cdr-text-heading-serif-500;
    font-weight: 600;
    margin-bottom: $cdr-space-one-x;
  }

  &__body {
    @include cdr-text-utility-sans-300;
  }
}
</style>
