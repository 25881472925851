import { defineStore } from 'pinia';
import { ref } from 'vue';

const useAlertStore = defineStore('alert', () => {
  /* State */
  const open = ref(false);
  const message = ref('');

  /* Actions */

  /**
   * Opens the overbooking alert
   */
  const openOverbookingAlert = () => {
    open.value = true;
    message.value =
      'Warning! There are items overbooked. Please make sure you select your store on PoR to resolve.';
  };

  return {
    /* State */
    open,
    message,
    /* Actions */
    openOverbookingAlert,
  };
});

export default useAlertStore;
