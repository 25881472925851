import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import { useIntervalFn } from '@vueuse/core';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import pageNames from '../../utils/constants';
import { msalInstance, loginRequest } from '../../auth/authConfig';

const useAppStore = defineStore('app', () => {
  /* State */
  const pageName = ref('');
  const loading = ref(false);
  const title = ref('');
  const msalEnabled = ref(false);
  const porUrl = ref('');
  const accessToken = ref('');
  const hour = 60 * 60 * 1000;

  /* Getters */
  const isCalendarPage = computed(() => pageName.value === pageNames.CALENDAR && !loading.value);

  /* Actions */

  /**
   * Sets the app props
   * @param {Object} props the app props
   */
  const init = (props) => {
    msalEnabled.value = props.msalEnabled;
    porUrl.value = props.porUrl;
  };

  /**
   * Retrieves a new access token
   */
  const fetchToken = async () => {
    const response = await msalInstance.acquireTokenSilent({ ...loginRequest }).catch(async (e) => {
      if (e instanceof InteractionRequiredAuthError) {
        await msalInstance.acquireTokenRedirect(loginRequest);
      }
      throw e;
    });
    accessToken.value = response.accessToken;
  };

  watch(msalEnabled, () => {
    useIntervalFn(fetchToken, hour);
  });

  return {
    /* State */
    pageName,
    loading,
    title,
    msalEnabled,
    porUrl,
    accessToken,
    /* Getters */
    isCalendarPage,
    /* Actions */
    init,
    fetchToken,
  };
});

export default useAppStore;
