<script setup>
import { CdrButton, CdrLink, CdrText, IconMinusStroke } from '@rei/cedar';
import useAppStore from '../../stores/global/app';
import useAlertStore from '../../stores/global/alert';

const app = useAppStore();
const alert = useAlertStore();
</script>

<template>
  <div
    v-if="alert.open"
    class="alert"
    data-ui="alert"
  >
    <div class="alert__text">
      <IconMinusStroke class="alert__icon" />
      <CdrText
        class="alert__message"
        data-ui="alert-message"
      >
        {{ alert.message }}
      </CdrText>
    </div>
    <div class="alert__buttons">
      <CdrButton
        class="alert__dismiss"
        modifier="link"
        data-ui="alert-dismiss"
        @click="alert.open = false"
      >
        Dismiss
      </CdrButton>
      <CdrLink
        class="alert__workbench"
        :href="app.porUrl"
        target="_blank"
      >
        Go to Dashboard
      </CdrLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.alert {
  display: flex;
  flex-direction: column;
  gap: $cdr-space-half-x;
  background-color: $cdr-color-background-message-error;
  padding: $cdr-space-three-quarter-x $cdr-space-one-and-a-half-x;
  width: fit-content;
  max-width: 343px;

  &__text {
    display: flex;
    flex-direction: row;
    gap: $cdr-space-half-x;
  }

  &__icon {
    width: $cdr-space-one-x;
    height: $cdr-space-one-x;
    fill: $cdr-color-icon-message-error;
    margin-top: $cdr-space-quarter-x;
  }

  &__message {
    @include cdr-text-utility-sans-300;
    color: #000;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: $cdr-space-one-and-a-half-x;
    justify-content: flex-end;
  }

  &__dismiss {
    @include cdr-text-utility-sans-200;
  }

  &__workbench {
    @include cdr-text-utility-sans-strong-200;
  }

  &__dismiss,
  &__workbench {
    padding: 0;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
