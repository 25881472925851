<script setup>
import { ref, computed } from 'vue';
import { CdrAccordionGroup, CdrAccordion, CdrText } from '@rei/cedar';
import TrafficGrid from './TrafficGrid.vue';
import CurrentTimeLine from './CurrentTimeLine.vue';
import useLocationStore from '../../stores/calendar/location';
import useTrafficStore from '../../stores/calendar/traffic';

const location = useLocationStore();
const traffic = useTrafficStore();

const accordions = ref(
  Object.keys(traffic.schedule).reduce((prev, cur, idx) => {
    const obj = {
      id: idx,
      opened: true,
      item: {
        name: cur,
        ...traffic.schedule[cur],
      },
    };
    return [...prev, obj];
  }, []),
);

const toggleAccordion = (idx) => {
  accordions.value[idx].opened = !accordions.value[idx].opened;
};

const getName = (accordion) => `${accordion.id + 1}. ${accordion.item.name}`;

const style = computed(() => ({
  '--num-of-blocks': location.times.length,
}));
</script>

<template>
  <CdrAccordionGroup>
    <CdrAccordion
      v-for="accordion in accordions"
      :id="accordion.item.name.toLowerCase()"
      :key="accordion.id"
      level="4"
      :opened="accordion.opened"
      :content-spacing="false"
      class="traffic-accordion"
      :style="style"
      @accordion-toggle="toggleAccordion(accordion.id)"
    >
      <template #label>
        <CurrentTimeLine
          :offset="traffic.offset + 30.5"
          type="traffic"
        />
        <div class="traffic-accordion__header">
          <CdrText class="traffic-accordion__item">{{ getName(accordion) }}</CdrText>
          <CdrText class="traffic-accordion__inventory">
            total inventory: {{ accordion.item.totalInventory }}
          </CdrText>
        </div>
      </template>
      <TrafficGrid
        :opened="accordion.opened"
        :index="accordion.id"
        :name="accordion.item.name"
        :going-out="accordion.item.goingOut"
        :returning="accordion.item.returning"
        :in-use="accordion.item.inUse"
      />
    </CdrAccordion>
  </CdrAccordionGroup>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.traffic-accordion {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $cdr-color-border-primary;
  border-right: none;
  border-left: none;
  border-bottom: none;
  width: calc(var(--num-of-blocks) * var(--cell-size) + 3 * $cdr-space-three-x);

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: var(--cell-size);
    border-left: 1px solid $cdr-color-border-primary;
    border-bottom: 1px solid $cdr-color-border-primary;
  }

  &__item,
  &__inventory {
    @include cdr-text-utility-sans-300;
    position: sticky;
    top: 0;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    left: 0;
    font-weight: 600;
    padding: $cdr-space-half-x;
    min-width: calc(3 * $cdr-space-three-x);
    background-color: $cdr-color-background-secondary;
    z-index: 10;
  }

  &__inventory {
    right: 40px;
    color: $cdr-color-text-secondary;
    background-color: $cdr-color-background-secondary;
    z-index: 10;
  }
}
</style>

<style lang="scss">
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.traffic-accordion {
  .time:nth-child(2n) {
    border-right-style: dashed;
  }

  button[class^='cdr-accordion__button'] {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $cdr-color-background-secondary;
    border-right: 1px solid $cdr-color-border-primary;
    border-bottom: 1px solid $cdr-color-border-primary;
    height: var(--cell-size);

    svg {
      position: sticky;
      top: 0;
      right: 0;
      z-index: 10;
      background-color: $cdr-color-background-secondary;
    }
  }

  span[class^='cdr-accordion__label'] {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  div[class^='cdr-accordion__content-container'] {
    overflow: unset;
  }
}
</style>
