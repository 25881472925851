import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { EventType } from '@azure/msal-browser';
import '@rei/cedar/dist/reset.css';
import '@rei/cedar/dist/cdr-fonts.css';
// Import styles from Cedar
import '@rei/cedar/dist/style/cdr-container.css';
import '@rei/cedar/dist/style/cdr-text.css';
import '@rei/cedar/dist/style/cdr-select.css';
import '@rei/cedar/dist/style/cdr-tabs.css';
import '@rei/cedar/dist/style/cdr-tab-panel.css';
import '@rei/cedar/dist/style/cdr-button.css';
import '@rei/cedar/dist/style/cdr-link.css';
import '@rei/cedar/dist/style/cdr-input.css';
import '@rei/cedar/dist/style/cdr-accordion-group.css';
import '@rei/cedar/dist/style/cdr-accordion.css';
import '@rei/cedar/dist/style/cdr-skeleton.css';
import '@rei/cedar/dist/style/cdr-skeleton-bone.css';
import '@rei/back-to-top-btn/dist/style.css';
import App from './App.vue';
import router from '../router';
import { registerGuard } from '../router/guard';
import CustomNavigationClient from '../router/CustomNavigationClient';
import msalPlugin from '../plugins/msalPlugin';
import { msalInstance } from '../auth/authConfig';
import log from '../utils/log';

const getClientPageData = () => {
  const model = window.document.getElementById('modelData');
  return model === null ? {} : JSON.parse(model.innerHTML);
};

const props = getClientPageData();

// Only add msal if its enabled from Conman
if (props.msalEnabled) {
  registerGuard(router);

  // Configure the MSAL instance to use vue-router
  const navigationClient = new CustomNavigationClient(router);
  msalInstance.setNavigationClient(navigationClient);

  // Select first account and make it the active one
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const { payload } = event;
      const { account } = payload;
      msalInstance.setActiveAccount(account);
    }
  });
}

const pinia = createPinia();
const app = createApp(App, props);
app.use(pinia);
app.use(router);
app.use(msalPlugin, msalInstance);

router
  .isReady()
  .then(() => {
    app.mount('#app');
  })
  .catch(() => {
    log('Vue router failed to initialize', 'error');
  });
