import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import useAppStore from '../global/app';
import useLocationStore from './location';
import useReservationsStore from './reservations';
import useTrafficStore from './traffic';
import useErrorStore from '../global/error';
import calculateLeftOffset from '../../utils/calculateLeftOffset';
import { convertStringToDate } from '../../utils/dates';

const useCalendarStore = defineStore('calendar', () => {
  /* State */
  const date = ref('Today');
  const currentTime = ref(new Date());
  const blockSize = ref(96);
  const activeTab = ref('reservations');
  const app = useAppStore();
  const location = useLocationStore();
  const reservations = useReservationsStore();
  const traffic = useTrafficStore();
  const error = useErrorStore();
  const scrollPos = ref(0);

  /* Getters */
  const currentTimeFormatted = computed(() => format(currentTime.value, 'HH:mm a'));
  const reservationsIntervalRatio = computed(() => location.interval / reservations.interval);

  /* Actions */

  /**
   * Update the current time by adding a minute
   */
  const updateTime = () => {
    currentTime.value = addMinutes(currentTime.value, 1);
  };

  /**
   * Calculates the leftoffset for the grid based on the current time and what tab is showing
   * @returns the leftoffset for the grid
   */
  const getLeftOffset = () => {
    const currentHours = currentTime.value.getHours();
    const currentMinutes = currentTime.value.getMinutes();
    switch (activeTab.value) {
      case 'traffic':
        return calculateLeftOffset(
          blockSize.value,
          traffic.interval,
          location.open,
          currentHours,
          currentMinutes,
        );
      default:
        return calculateLeftOffset(
          blockSize.value,
          reservations.interval,
          location.open,
          currentHours,
          currentMinutes,
        );
    }
  };

  /**
   * Handle async logic to retrieve reservation and traffic data. If the reservations API has errors
   * skip the traffic API call.
   * @param {Object} opts fetch options
   */
  const getReservationAndTrafficData = async (opts = {}) => {
    await reservations.getReservationsByCompanyId(location.companyId, date.value, opts);
    if (!error.external && !error.internal) {
      await traffic.getTrafficScheduleByStoreNumber(location.storeNumber, date.value, opts);
    }
  };

  /**
   * Updates the date and cause a reselct of the location's day
   * @param {String} d the selected date
   * @param {Object} opts fetch options
   */
  const updateDate = async (d, opts = {}) => {
    const { accessToken } = app;
    const options = {
      ...opts,
      accessToken,
    };

    app.loading = true;
    date.value = d;

    const convertedDate = format(convertStringToDate(date.value), 'yyyy-MM-dd');
    await location.getLocationByStoreNumber(convertedDate, options);

    // Check if error occurred selecting the location's hours
    if (!error.internal) {
      await getReservationAndTrafficData(options);
    }

    scrollPos.value = getLeftOffset() - 144;
    app.loading = false;
  };

  /**
   * Initializes the calendar API calls which populates the child stores: location, reservations, and traffic.
   * Hanldes errors after each API call.
   * @param {Object} opts fetch options
   */
  const init = async (opts = {}) => {
    const { accessToken } = app;
    const options = {
      ...opts,
      accessToken,
    };

    const convertedDate = format(convertStringToDate(date.value), 'yyyy-MM-dd');
    await location.getLocationByStoreNumber(convertedDate, options);

    if (!error.external && !error.internal) {
      await getReservationAndTrafficData(options);
    }

    app.loading = false;
    app.title = location.name;
  };

  /**
   * Refreshes reservations and traffic data as long as there are no errors
   * @param {Object} opts fetch options
   */
  const refresh = async (opts = {}) => {
    const { accessToken } = app;
    const options = {
      ...opts,
      accessToken,
    };

    if (!error.internal && !error.external) {
      await getReservationAndTrafficData(options);
    }
  };

  watch(
    () => [activeTab.value, app.loading],
    () => {
      if (!app.loading) {
        scrollPos.value = getLeftOffset() - 144;
      }
    },
  );

  return {
    /* State */
    date,
    currentTime,
    blockSize,
    activeTab,
    app,
    location,
    reservations,
    traffic,
    error,
    scrollPos,
    /* Getters */
    currentTimeFormatted,
    reservationsIntervalRatio,
    /* Actions */
    updateTime,
    updateDate,
    getLeftOffset,
    init,
    refresh,
    getReservationAndTrafficData,
  };
});

export default useCalendarStore;
