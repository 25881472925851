import { fetchWrapper } from '../helpers';
import useErrorStore from '../../stores/global/error';
import log from '../../utils/log';

/**
 * Hanldes the location API response and sets error messages accordingly
 * @param {Number} storeNumber the PoR company id
 * @param {String} date the current date
 * @param {Object} opts options to configure fetch call
 * @returns api response
 */
const getLocationHoursByStoreNumber = (storeNumber, date, opts = {}) => {
  const error = useErrorStore();
  return fetchWrapper(`/rs/location/hours/${storeNumber}?date=${date}`, opts)
    .then((res) => {
      if (res.storeName) {
        res.storeName = res.storeName.replace('REI Co-op Rentals: ', '');
      }
      if (!res.hours || (res.hours && (!res.hours.openTime || !res.hours.closeTime))) {
        error.internal = true;
        error.title = 'Uh oh!';
        error.body = 'Your store is closed today! Please select a different date.';
      } else {
        error.internal = false;
        error.title = '';
        error.body = '';
      }
      return res;
    })
    .catch((res) => {
      log(
        `Error in get location hours call: ${res.details.message}\n${JSON.stringify(
          res.details,
          null,
          2,
        )}`,
        'error',
      );
      error.external = true;
      return res;
    });
};

export default getLocationHoursByStoreNumber;
