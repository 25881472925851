import fetch from 'cross-fetch';

/**
 * Hanldes API errors and forwards a JSON payload.
 *
 * If the response is ok and parsable to JSON return the JSON body.
 *
 * If the response is ok and not parsable to JSON return the response to be handled downstream.
 *
 * Throw an error if there is an API error.
 *
 * @param {Object} res the the API response
 * @returns an object
 * @throws an error with information related to the API failure
 */
export const handleErrors = async (res) => {
  let json;

  try {
    json = await res.json();
  } catch (e) {
    // Do nothing here, assess response status
  }

  if (!res.ok) {
    const error = new Error(res.statusText);
    const e = {
      url: res.url,
      status: res.status,
      statusText: res.statusText,
      ...json,
    };
    error.details = e;
    throw error;
  }

  // Return response to be handled upstream when JSON fails to parse
  return json || res;
};

/**
 * Handles the API call while chaining the handle errors method
 * @param {String} url the API url to call
 * @param {Object} opts fetch options
 * @returns the API response
 */
export const fetchWrapper = (url, opts = {}) => {
  const baseUrl = opts.baseUrl || '';
  const accessToken = opts.accessToken || '';
  const method = opts.method || 'GET';
  const options = { method };

  if (accessToken) {
    const bearer = `Bearer ${accessToken}`;
    const headers = {
      Authorization: bearer,
    };

    options.headers = headers;
  }

  return fetch(`${baseUrl}${url}`, options).then(handleErrors);
};
