import format from 'date-fns/format';
import { fetchWrapper } from '../helpers';
import useErrorStore from '../../stores/global/error';
import log from '../../utils/log';
import { convertStringToDate } from '../../utils/dates';

/**
 * Hanldes the traffic API response and sets error messages accordingly
 * @param {String} storeNumber the PoR store number
 * @param {String} date the selected date
 * @param {Object} opts options to configure fetch call
 * @returns api response
 */
const getTrafficScheduleByStoreNumber = (storeNumber, date, opts = {}) => {
  const error = useErrorStore();
  const formattedDate = format(convertStringToDate(date), 'yyyy-MM-dd');
  return fetchWrapper(`/rs/traffic/${storeNumber}?date=${formattedDate}`, opts)
    .then((res) => {
      // If status is 204 set internal error for calendar page
      if (res.status === 204) {
        error.internal = true;
        error.title = 'Yey!';
        error.body = 'There are no more open reservations today.';
        return null;
      }
      return res;
    })
    .catch((res) => {
      log(
        `Error in get traffic schedule call: ${res.details.message}\n${JSON.stringify(
          res.details,
          null,
          2,
        )}`,
        'error',
      );
      error.external = true;
      return null;
    });
};

export default getTrafficScheduleByStoreNumber;
