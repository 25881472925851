import { reactive } from 'vue';
import { EventMessageUtils, EventType, InteractionStatus } from '@azure/msal-browser';

/**
 * Helper function to determine whether 2 arrays are equal
 * Used to avoid unnecessary state updates
 * @param arrayA
 * @param arrayB
 */
export function accountArraysAreEqual(arrayA, arrayB) {
  if (arrayA.length !== arrayB.length) {
    return false;
  }

  const comparisonArray = [...arrayB];

  return arrayA.every((elementA) => {
    const elementB = comparisonArray.shift();
    if (!elementA || !elementB) {
      return false;
    }

    return (
      elementA.homeAccountId === elementB.homeAccountId &&
      elementA.localAccountId === elementB.localAccountId &&
      elementA.username === elementB.username
    );
  });
}

/**
 * Assess the event callback type and performs the correct action
 * @param {Object} msalInstance the msal instance
 * @param {Object} state msal props to mount to Vue
 * @param {EventMessage} message the callback message
 */
export const eventCallback = (msalInstance, state, message) => {
  switch (message.eventType) {
    case EventType.ACCOUNT_ADDED:
    case EventType.ACCOUNT_REMOVED:
    case EventType.LOGIN_SUCCESS:
    case EventType.SSO_SILENT_SUCCESS:
    case EventType.HANDLE_REDIRECT_END:
    case EventType.LOGIN_FAILURE:
    case EventType.SSO_SILENT_FAILURE:
    case EventType.LOGOUT_END:
    case EventType.ACQUIRE_TOKEN_SUCCESS:
    case EventType.ACQUIRE_TOKEN_FAILURE:
      // eslint-disable-next-line no-case-declarations
      const currentAccounts = msalInstance.getAllAccounts();
      if (!accountArraysAreEqual(currentAccounts, state.accounts)) {
        // eslint-disable-next-line no-param-reassign
        state.accounts = currentAccounts;
      }
      break;
    default:
      break;
  }

  const status = EventMessageUtils.getInteractionStatusFromEvent(message, state.inProgress);
  if (status !== null) {
    // eslint-disable-next-line no-param-reassign
    state.inProgress = status;
  }
};

export default {
  /**
   * Install MSAL plugin in the Vue application
   * @param {Object} app the Vue app to add MSAL to
   * @param {Object} msalInstance the MSAL instance to add
   */
  install: (app, msalInstance) => {
    const inProgress = InteractionStatus.Startup;
    const accounts = msalInstance.getAllAccounts();

    const state = reactive({
      instance: msalInstance,
      inProgress,
      accounts,
    });

    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$msal = state;

    msalInstance.addEventCallback((message) => {
      eventCallback(msalInstance, state, message);
    });
  },
};
