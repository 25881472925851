import { msalInstance, loginRequest } from '../auth/authConfig';

/**
 * Determines whether the user is autheticated or not and prompts for sign ins when not
 * @param {Object} instance the msal instance
 * @param {Object} loginRequestParam the login request
 * @returns whether the user is authenticated or not
 */
export const isAuthenticated = async (instance, loginRequestParam) => {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
  return instance
    .handleRedirectPromise()
    .then(() => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        return true;
      }

      // User is not signed in and attempting to access protected route. Sign them in.
      return instance
        .loginRedirect(loginRequestParam)
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    })
    .catch(() => {
      return false;
    });
};

/**
 * Adds the route guard to each route
 * @param {Object} to info about where the router is going
 * @param {Object} instance the msal instance
 * @param {Object} loginRequestParam the login request
 * @returns the failed route or true
 */
export const route = async (to, instance, loginRequestParam) => {
  if (to.meta.requiresAuth) {
    const request = {
      ...loginRequestParam,
      redirectStartPage: to.fullPath,
    };
    const shouldProceed = await isAuthenticated(instance, request);
    return shouldProceed || '/failed';
  }

  return true;
};

/**
 * Adds the route guard to each route in Vue router
 * @param {Object} router the Vue router
 */
export const registerGuard = (router) => {
  router.beforeEach((to) => route(to, msalInstance, loginRequest));
};
