<script setup>
import { computed } from 'vue';
import { CdrText } from '@rei/cedar';
import TimeComponent from './TimeComponent.vue';
import useLocationStore from '../../stores/calendar/location';

const props = defineProps({
  opened: { type: Boolean, required: true },
  goingOut: { type: Array, required: true },
  returning: { type: Array, required: true },
  inUse: { type: Array, required: true },
});

const location = useLocationStore();

const createValue = (val) => {
  return val > 0 ? `${val}` : '';
};

const returningClasses = (val) => ({
  'traffic-grid__type--returning': val > 0,
});

const rowClasses = computed(() => ({
  'traffic-grid__row': true,
  'traffic-grid__row--closed': !props.opened,
}));
</script>

<template>
  <div class="traffic-grid">
    <div :class="rowClasses">
      <CdrText class="traffic-grid__type">Going out</CdrText>
      <TimeComponent
        v-for="idx in location.times.length"
        :key="idx"
        :text="createValue(props.goingOut[idx - 1])"
        :class="returningClasses(props.goingOut[idx - 1])"
      />
    </div>
    <div :class="rowClasses">
      <CdrText class="traffic-grid__type">Returning</CdrText>
      <TimeComponent
        v-for="idx in location.times.length"
        :key="idx"
        :text="createValue(props.returning[idx - 1])"
      />
    </div>
    <div :class="rowClasses">
      <CdrText class="traffic-grid__type">In use</CdrText>
      <TimeComponent
        v-for="idx in location.times.length"
        :key="idx"
        :text="createValue(props.inUse[idx - 1])"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.traffic-grid {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $cdr-color-border-primary;

  &__row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $cdr-color-border-primary;

    &--closed {
      display: none;
    }
  }

  &__type {
    @include cdr-text-utility-sans-300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: sticky;
    top: 0;
    left: 0;
    color: $cdr-color-text-emphasis;
    padding: 10px $cdr-space-three-quarter-x;
    width: calc(3 * $cdr-space-three-x);
    border-left: 1px solid $cdr-color-border-primary;
    border-right: 1px solid $cdr-color-border-primary;
    background-color: #fff;

    &--returning {
      font-weight: 600;
      color: $cdr-color-text-emphasis;
      background-color: $cdr-color-background-message-success;
    }
  }
}
</style>
