<script setup>
import { computed } from 'vue';
import { CdrText } from '@rei/cedar';
import useCalendarStore from '../../stores/calendar';

const props = defineProps({
  offset: { type: Number, required: true },
  type: { type: String, default: 'reservations' },
});

const calendar = useCalendarStore();

const style = computed(() => ({
  '--offset': `${props.offset + calendar.getLeftOffset(props.type)}px`,
}));
</script>

<template>
  <div
    class="current-time"
    :style="style"
  >
    <CdrText class="current-time__text">{{ calendar.currentTimeFormatted }}</CdrText>
    <div class="current-time__line" />
  </div>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/scss/cdr-tokens';

.current-time {
  position: absolute;
  top: 0;
  left: var(--offset);
  z-index: 10;
  width: calc($cdr-space-four-x + 3px);

  &__text {
    @include cdr-text-utility-sans-strong-100;
    color: #47bcc1;
    text-align: center;
  }

  &__line {
    width: 3px;
    height: $cdr-space-four-x;
    background-color: #47bcc1;
    margin: auto;
  }
}
</style>
