import { defineStore } from 'pinia';
import { ref } from 'vue';
import getTrafficScheduleByStoreNumberApi from '../../api/calendar/traffic';

const useTrafficStore = defineStore('traffic', () => {
  /* State */
  const interval = ref(30);
  const offset = ref(110.5);
  const schedule = ref({});

  /* Actions */

  /**
   * Updates traffic schedule information with data from traffic API call
   * @param {String} storeNumber the PoR store number
   * @param {String} date the selected date
   * @param {Object} opts fetch options
   */
  const getTrafficScheduleByStoreNumber = async (storeNumber, date, opts = {}) => {
    const json = await getTrafficScheduleByStoreNumberApi(storeNumber, date, opts);
    if (json) {
      schedule.value = json;
    }
  };

  return {
    /* State */
    interval,
    offset,
    schedule,
    /* Actions */
    getTrafficScheduleByStoreNumber,
  };
});

export default useTrafficStore;
